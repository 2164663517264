<template>
	<div style="background-color: #f8f9fb; min-height: 100%" ref="post_list_item_view">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button class="arrow_white" @click="goBack">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">MAF 포인트</h2>
			</div>
		</div>

		<div class="section_wrap pt-50">
			<div class="container ">
				<div class="row">
					<!-- 포인트 잔액 박스 -->
					<div class="point_box mt-20">
						<div class="flex justify_content_between align_items_center">
							<img :src="require('@/assets/image/ico_mafpoint.svg')" alt="" />
							<div class="flex-column" style="align-items: end; gap: 4px">
								<span class="size-px-13">MAF 포인트 잔액</span>
								<div class="point_box_balance">
									<p>{{  item_point.holding_point | makeComma }}</p>
									<span>P</span>
								</div>
							</div>
						</div>
						<hr class="under-line mt-15 mb-15" style="border: 1px solid rgba(255, 255, 255, 0.1)" />
						<div class="point_exchange"  @click="toExchange">
							<img :src="require('@/assets/image/ico_exchange.svg')" alt="" />
							<p>MAF 코인 교환</p>
							<button class="arrow_white ml-5">
								<i class="icon icon-arrow-right"></i>
							</button>
						</div>
					</div>

					<!-- 포인트 2배 적립 구매하기 -->
					<div class="white_box mt-10"  @click="toNft">
						<p class="size-px-14">메타마피아 NFT A등급 혜택</p>
						<strong class="size-px-18" style="color: #293097; font-weight: 900">포인트 2배 적립</strong>
						<div class="mt-10">
							<button class="size-px-13 color-gray">구매하기</button>
						</div>
						<div class="character_banner"></div>
					</div>

					<!-- 출석체크 혜택/포인트 추가적립 -->
					<div class="flex justify_content_between gap-1 mt-10">
						<div class="white_box text-center flex-1 pa-10-20" @click="toAttendance">
							<img :src="require('@/assets/image/ico_attendance_calendar.svg')" alt="" />
							<p class="size-px-16 fw-700 color-333 mt-5">출석체크 혜택</p>
							<p class="size-px-13 color-gray mt-5">매일 출석체크하고<br />MAF 포인트 받으세요</p>
						</div>
						<div class="white_box text-center flex-1 pa-10-20" @click="toMore">
							<img :src="require('@/assets/image/ico_mafpoint_coin.svg')" alt="" />
							<p class="size-px-16 fw-700 color-333 mt-5">포인트 추가적립</p>
							<p class="size-px-13 color-gray mt-5">카르텔 소지자와<br />일반 멤버 추가 적립</p>
						</div>
					</div>

					<div class="pt-25">
						<div class="input-date-box">
							<button class="btn_arrow" @click="prev"><i class="icon-arrow-left"></i></button>
							<!-- TODO : 프론트작업요청 -->
							<span class="size-px-18">{{ item_search.sDate.slice(0, 7) }}</span>
							<button class="btn_arrow" @click="next"><i class="icon-arrow-right"></i></button>
						</div>
					</div>

					<div class="mt-20">
						<ul class="tabs">
							<!-- 선택 시 active 클래스 토글 -->
							<li class="tabs_link" :class="{active: item_search.type == ''}" @click="setSearch('')">전체</li>
							<li class="tabs_link" :class="{active: item_search.type == '+'}" @click="setSearch('+')">적립</li>
							<li class="tabs_link" :class="{active: item_search.type == '-'}" @click="setSearch('-')">사용</li>
						</ul>
					</div>

					<div class="mt-10">
						<!-- 탭 컨텐츠 active 추가로 display none : block 처리함 -->
						<!-- 상태값을 하단에 배열로 정리해두었습니다 -->

						<template
							v-if="items_list.length > 0"
						>
							<div
								v-for="(point, p_index) in items_list"
								:key="'point_' + p_index"
								class="tabs_content active"
							>
								<ul
								>
									<div class="flex justify-space-between">
										<p class="size-px-14">MAF 포인트</p>
										<p class="size-px-16 fw-700" :class="point.color">{{ point.p_point}}{{ point.accumulate_use_point }}</p>
									</div>
									<div class="flex justify-space-between pt-10">
										<p class="size-px-14">구분</p>
										<p class="size-px-14 fw-500">{{ point.point_accumulate_use_code_name }}</p>
									</div>
									<div class="flex justify-space-between pt-10">
										<p class="size-px-14">일시</p>
										<p class="size-px-14 fw-500">{{ point.point_accumulate_use_day }}</p>
									</div>
								</ul>
							</div>
						</template>

						<Empty
							v-else
							class="mt-50"
						></Empty>
					</div>
				</div>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_banner"
		>
			<template
				v-slot:body
			>
				<div class="radius-20">
					<img :src="require('@/assets/image/event/event_point.png')" class="width-100"/>

					<div class="btn_wrap">
						<button class="pop_btn btn_fill_blue" @click="offBanner">확인</button>
					</div>
				</div>
			</template>
		</PopupLayer>

		<Popup_confirm
			v-if="is_on_soldout"
			:not_cancel="true"

			@click="is_on_soldout = false"
		>
			<template v-slot:title>SOLD OUT</template>
			<template
				v-slot:main-txt
			>
				<div>A등급 카드가 판매 종료되었습니다.</div>
			</template>
			<template
				v-slot:sub-txt
			>
				<div>다음시즌 A등급 카드 판매를 준비중에 있습니다.</div>
			</template>
			<template
				v-slot:name-confirm
			>{{ $language.common.ok }}
			</template>
		</Popup_confirm>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"
			:top="$refs.cartel_cover"

			@change="getSearchData"
		></ScrollPagination>
	</div>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	import Empty from "@/view/Layout/Empty";
	import ScrollPagination from "@/components/ScrollPagination";
	export default {
		name: 'Point'
		, components: {ScrollPagination, Empty, Popup_confirm, PopupLayer}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: ''
					, title: ''
					, type: 'mypage'
					, not_footer: true
				}
				, is_on_banner: false
				, is_on_soldout: false
				, is_soldout: true
				, item_search: {
					sDate: this.$date.getToday('-')
					, type: ''
					, page: 1
					, page_number: 1
					, list_cnt: 10
					, point_accumulate_use_state_code: []
				}
				, item_point: {}
				, items: []
				, max: false
				, is_add: false
			}
		}
		, computed: {

			pointList: function() {
				let t = [
					{name: '출석체크 보상', score: '+0.2'},
					{name: '댓글 보상', score: '+0.1'},
					{name: '공감 보상', score: '+0.1'},
					{name: '추천인 보상', score: '+1'},
					{name: '내 카르텔 신규 멤버 보상', score: '+1'},
					{name: '내 카르텔 공감 보상', score: '+0.02'},
					{name: '추천인 본인 가입 보상', score: '+5'},
					{name: 'MAF 코인 전환', score: '-1,000'},
				];

				return t
			}

			, items_list: function(){
				return this.items.filter( ( item ) => {
					if(item.point_accumulate_use_state_code.indexOf('PT004') > -1){
						item.p_point = '+'
						item.color = 'color-blue'
					}else{
						item.p_point = '-'
						item.color = 'color-red'
					}
					if(this.item_search.type != ''){
						if(item.p_point != this.item_search.type){
							return false
						}
					}
					return item
				})
			}
		}
		, methods: {
			goBack: function(){
				this.$bus.$emit('goBack')
			}
			, toAttendance: function(){
				this.$bus.$emit('to', {name: 'Attendance'})
			}
			, toMore: function(){
				this.$bus.$emit('to', {name: 'PointMore'})
			}
			, offBanner: function(){
				sessionStorage.setItem('pointBanner', '1')
				this.is_on_banner = false
			}
			, toNft: function(){
				if(this.is_soldout){
					this.is_on_soldout = true
				}
			}
			, prev: function(){
				this.item_search.sDate = this.$date.getPrevMonth(this.item_search.sDate, 0, '-', 1).fullDate
				this.getPointList()
			}
			, next: function(){
				this.item_search.sDate = this.$date.getNextMonth(this.item_search.sDate, 0, '-', 1).fullDate
				this.getPointList()
			}
			, toExchange: function(){
				this.$bus.$emit('to', { name: 'Exchange'})
			}
			, async getData(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_my_point
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item_point = result.data
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})

				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, async getPointList(){
				try{
					this.is_add = false
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_point_total_list
						, data: {
							member_number: this.user.member_number
							, year: Number(this.item_search?.sDate?.slice(0, 4))
							, mm: Number(this.item_search?.sDate?.slice(5, 7))
							, page_number: this.item_search.page
							, pagerecnum: this.item_search.list_cnt
							, point_accumulate_use_state_code: this.item_search.point_accumulate_use_state_code
						}
						, type: true
					})
					if(result.success){

						if(result.data.point_accumulate_use_particulars_list.length > 0){
							this.items = this.items.concat(result.data.point_accumulate_use_particulars_list)
							this.max = false
						}else{
							this.max = true
						}
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})

				}finally {
					this.$bus.$emit('on', false)
					this.is_add = true
				}
			}
			, setSearch: function(type){
				switch (type){
					case '+':
						this.item_search.point_accumulate_use_state_code = ['PT00400001', 'PT00400002', 'PT00400003', 'PT00400004']
						break;
					case '-':
						this.item_search.point_accumulate_use_state_code = ['PT00600001', 'PT00600002', 'PT00600003']
						break;
					default:
						this.item_search.point_accumulate_use_state_code = []
						break;
				}
				this.item_search.type = type
				this.getPointList()
			}
			, getSearchData: function(page){

				if(page){
					this.item_search.page = page
					this.item_search.page_number = page
				}

				this.getPointList()
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			let t = sessionStorage.getItem('pointBanner')
			if(!t){
				this.is_on_banner = true
			}

			this.getData()
			this.getPointList()
		}
	}
</script>

<style>
input[type='date'] {
	background: unset;
}
input[type='date' i]::-webkit-calendar-picker-indicator {
	padding: 0;
	background: url(../../assets/image/ico_calendar.svg) no-repeat center center;
	background-size: 24px;
	width: 24px;
	height: 24px;
}
</style>
